import React from 'react';
import { useDragLayer } from 'react-dnd';

const CustomDragLayer = () => {
  const { item, isDragging, clientOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    isDragging: monitor.isDragging(),
    clientOffset: monitor.getClientOffset(),
  }));

  if (!isDragging || !clientOffset) {
    return null;
  }

  const { x, y } = clientOffset;
  const { word, status } = item; // Destructure the status from the item

  // Determine background color based on status
  const backgroundColor = status === 'correct'
    ? '#228B22' // Green
    : status === 'wrong place'
    ? '#FF8C00' // Orange
    : status === 'incorrect'
    ? '#C32148' // Red
    : '#000080'; // Default blue

    const style = {
      position: 'fixed',
      pointerEvents: 'none',
      left: x,
      top: y,
      transform: 'translate(-50%, -50%)', // Center the word under the cursor/finger
      zIndex: 1000,
      backgroundColor: backgroundColor, // Use the background color
      color: 'white',
      padding: '6px 8px',
      borderRadius: '8px',
      userSelect: 'none'
    };
    

  return (
    <div style={style}>
      <span className="worddrag">{word}</span>
    </div>
  );
};

export default CustomDragLayer;
