// src/components/StartScreen.js
import React from 'react';
import ReactGA from 'react-ga4'; // Import GA

const StartScreen = ({ onStart, isLoading, disabled }) => (
  <div className="start-screen">
          <div className="game-start-title-container">
          <div className="game-start-title">Alternative Facts</div>
          </div>
    <h2>A daily satirical puzzle</h2>
    <p>
      Can you sort fact from fiction? Fill in the blanks to complete today's puzzle.
      Drag and drop the correct words into the gaps in the analogies.
      You have 6 tries to guess them all correctly. <span style={{ color: '#C32148', fontWeight: 'bold' }}>Red</span> means an incorrect guess, <span style={{ color: '#FF8C00', fontWeight: 'bold' }}>orange</span> means the word is in the wrong place, <span style={{ color: '#228B22', fontWeight: 'bold' }}>green</span> means you got it right. Good luck!
    </p>
    <button
      onClick={() => {
        if (!isLoading && !disabled) {
          // Track the game start event
          ReactGA.event({
            action: 'game_started',
            params: {
              category: 'User',
              label: 'Game Start',
            },
          });

          // Call the original onStart function
          onStart();
        }
      }}
      disabled={isLoading || disabled}
    >
      {isLoading ? 'Loading...' : disabled ? 'Game Completed' : 'Start Game'}
    </button>
  </div>
);


export default StartScreen;
