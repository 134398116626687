import React from 'react';
import { useDrag } from 'react-dnd';

const Word = ({ word, status = 'unused' }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'WORD',
    item: { word, status }, // Pass status with the word
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  // Determine background color based on status
  const backgroundColor = status === 'correct'
    ? '#228B22' // Green
    : status === 'wrong place'
    ? '#FF8C00' // Orange
    : status === 'incorrect'
    ? '#C32148' // Red
    : '#000080'; // Default blue

  return (
    <span
      ref={drag}
      className="word"
      style={{
        opacity: isDragging ? 0 : 1, // Hide the original word during dragging
        backgroundColor: backgroundColor,
        cursor: 'grab',
        padding: '6px 8px',
        borderRadius: '8px',
        color: 'white',
        userSelect: 'none',
        margin: '6px',
        fontSize: '14px',
      }}
    >
      {word}
    </span>
  );
};


export default Word;
