import React, { useState, useEffect } from 'react';

const EditPage = () => {
  const [recentFolders, setRecentFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState('');
  const [alternativeKeywords, setAlternativeKeywords] = useState('');
  const [labelledAnalogies, setLabelledAnalogies] = useState(['', '', '']);
  const [satiricalColumn, setSatiricalColumn] = useState('');
  const [headline, setHeadline] = useState('');
  const [referenceColumns, setReferenceColumns] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [selectedResult, setSelectedResult] = useState(null);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // Fetch recent folders from the backend on component mount
  useEffect(() => {
    const fetchRecentFolders = async () => {
      try {
        const res = await fetch(`${backendUrl}/get-recent-folders`);
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        const data = await res.json();
        setRecentFolders(data.folders);  // Assuming the backend returns { folders: [...] }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching recent folders:', error);
        setIsLoading(false);
      }
    };

    fetchRecentFolders();
  }, [backendUrl]);

  // Handle folder selection
  const handleFolderChange = (event) => {
    setSelectedFolder(event.target.value);
  };

  // Handle loading the data for the selected folder
  const handleLoadData = async () => {
    if (!selectedFolder) {
      alert('Please select a folder before loading.');
      return;
    }
  
    console.log(`Attempting to load game data for folder ID: ${selectedFolder}`);
  
    try {
      const res = await fetch(`${backendUrl}/get-json-by-id/${selectedFolder}`);
      if (!res.ok) {
        console.error(`Failed to fetch game data. HTTP status: ${res.status}`);
        throw new Error(`HTTP error! status: ${res.status}`);
      }
  
      const data = await res.json();
      console.log('Game data received successfully:', data);
  
      // Find the result that contains the final_alternative_keywords and save its index
      const resultIndex = data.results.findIndex(
        (r) => r.final_alternative_keywords && r.final_alternative_keywords.length > 0
      );
  
      const result = data.results[resultIndex]; // Use the index to retrieve the result
  
      if (result) {
        console.log('Found result with final_alternative_keywords:', result);
  
        // Set the headline, keywords, analogies, and satirical column in state
        setHeadline(result.headline || ''); // Ensure you load the headline
        setAlternativeKeywords(result.final_alternative_keywords.join(', '));
        setLabelledAnalogies(result.labelled_analogies || ['', '', '']);
        setSatiricalColumn(result.satirical_column || '');
  
        // Save the result for future editing, including the index
        setSelectedResult({ ...result, index: resultIndex });
  
        // Pull reference columns
        const referenceColumns = data.results
          .filter((r, idx) => idx !== resultIndex && r.satirical_column) // Exclude the selected result
          .map((r) => ({ headline: r.headline, column: r.satirical_column }));
        setReferenceColumns(referenceColumns);
  
        console.log('State updated with selected result and headline.');
      } else {
        console.warn('No result found with final_alternative_keywords in the game data.');
        setAlternativeKeywords(''); // Set empty if not available
        setSelectedResult(null); // Clear the selected result if not found
        setLabelledAnalogies(['', '', '']);
        setSatiricalColumn('');
        setHeadline(''); // Clear headline
        setReferenceColumns([]);
      }
    } catch (error) {
      console.error('Error occurred while fetching game data:', error);
      alert('Failed to load game data.');
    }
  };
  
  

  // Handle changes in the keywords, labelled analogies, satirical column, and headline textareas
  const handleKeywordsChange = (event) => {
    setAlternativeKeywords(event.target.value);
  };

  const handleAnalogyChange = (index, event) => {
    const updatedAnalogies = [...labelledAnalogies];
    updatedAnalogies[index] = event.target.value;
    setLabelledAnalogies(updatedAnalogies);
  };

  const handleSatiricalColumnChange = (event) => {
    setSatiricalColumn(event.target.value);
  };

  const handleHeadlineChange = (event) => {
    setHeadline(event.target.value);
  };

  // Save updated data to the server
  const handleSave = async () => {
    if (!selectedFolder) {
      alert('Please select a folder and load data before saving.');
      return;
    }
  
    if (!selectedResult) {
      alert('No result selected for update.');
      return;
    }
  
    try {
      const res = await fetch(`${backendUrl}/update-article/${selectedFolder}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          final_alternative_keywords: alternativeKeywords.split(', ').map(kw => kw.trim()), // Split into array
          labelled_analogies: labelledAnalogies, // Send the labelled analogies
          satirical_column: satiricalColumn, // Send the satirical column
          headline: headline, // Send the headline
          selectedResultIndex: selectedResult.index // Send the index of the selected result
        }),
      });
  
      if (res.ok) {
        alert('Article updated successfully!');
      } else {
        alert('Error updating article.');
      }
    } catch (error) {
      console.error('Error saving article:', error);
      alert('Error saving article.');
    }
  };
  


  return (
    <div className="edit-page-container">
      <h1>Edit Article</h1>
      {isLoading ? (
        <p>Loading folders...</p>
      ) : (
        <>
          <label htmlFor="folderSelect">Select a recent folder:</label>
          <select id="folderSelect" value={selectedFolder} onChange={handleFolderChange} className="large-input">
            <option value="">--Select a folder--</option>
            {recentFolders.map((folder) => (
              <option key={folder} value={folder}>
                {folder}
              </option>
            ))}
          </select>

          <button onClick={handleLoadData} className="large-button">Load</button>

          {selectedResult && (
            <>
              <h2>Edit Alternative Keywords</h2>
              <textarea
                value={alternativeKeywords}
                onChange={handleKeywordsChange}
                rows={5}
                cols={50}
                className="large-textarea"
              />

              <h2>Edit Headline</h2>
              <textarea
                value={headline}
                onChange={handleHeadlineChange}
                rows={2}
                cols={80}
                className="large-textarea"
              />

              <h2>Edit Analogies</h2>
              {labelledAnalogies.map((analogy, index) => (
                <div key={index}>
                  <label>{`Analogy ${index + 1}`}</label>
                  <textarea
                    value={analogy}
                    onChange={(e) => handleAnalogyChange(index, e)}
                    rows={3}
                    cols={50}
                    className="large-textarea"
                  />
                </div>
              ))}

              <h2>Edit Satirical Column</h2>
              <textarea
                value={satiricalColumn}
                onChange={handleSatiricalColumnChange}
                rows={15}
                cols={80}
                className="large-textarea"
              />

              <button onClick={handleSave} className="large-button">Save Changes</button>

              <h2>Reference Articles</h2>
              {referenceColumns.map((ref, index) => (
                <div key={index}>
                  <h3>{`Reference Headline ${index + 1}`}</h3>
                  <p><strong>{ref.headline}</strong></p>
                  <div
                    style={{
                      whiteSpace: 'pre-wrap', // Preserves the line breaks
                      border: '1px solid #ccc',
                      padding: '10px',
                      marginBottom: '20px',
                      backgroundColor: '#f9f9f9'
                    }}
                    dangerouslySetInnerHTML={{ __html: ref.column.replace(/\n/g, '<br />') }}
                  />
                </div>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default EditPage;
